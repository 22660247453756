import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IBooking } from "@sportyano/account/components/my-booking/models/interfaces/booking.interface";
import { permission } from "@sportyano/core/models/permissions/permission";
import { IPlaygroundDetailsResponse } from "@sportyano/core/models/playgrounds/playground.interface";
import { IProgramDetailsResponse } from "@sportyano/core/models/programs/program-details";
import { TimeSlotResponse } from "@sportyano/core/models/programs/time-slots.model";
import { IPaginatedResponseData } from "@sportyano/core/models/response/response.interface";
import { UrlQueryBuilder } from "@sportyano/shared/utilis/url-query-builder";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class BookingService {
	private readonly programLocalStorageKey = "selectedProgramId";
	private apiUrl = environment.baseUrl + "academies/booking";

	storeProgramId(programId: string) {
		localStorage.setItem(this.programLocalStorageKey, programId);
	}

	getProgramId(): string | null {
		return localStorage.getItem(this.programLocalStorageKey);
	}

	constructor(private http: HttpClient) {}

	/**
	 * Retrieves a list of bookings.
	 * @param {boolean} is_cancelled
	 * @param starting_date - upcoming / past .
	 * @param type - program / schadule.
	 */
	getBookings(
		is_cancelled: boolean,
		starting_date: string | null,
		type: string
	): Observable<IPaginatedResponseData<IBooking>> {
		const urlQuery = UrlQueryBuilder.buildUrlQuery(
			environment.baseUrl,
			"booking/list",
			{
				starting_date: starting_date,
				is_cancelled: is_cancelled,
				bookable: type,
			}
		);
		return this.http.get<IPaginatedResponseData<IBooking>>(urlQuery.toString());
	}

	/**
	 * Cancels a booking
	 * @param bookingId
	 */
	cancelBooking(bookingId: string): Observable<any> {
		const url = `${this.apiUrl}/cancel/${bookingId}`;
		return this.http.post(url, {});
	}

	getProgramTimeSlots(
		id: string | null,
		userType: string
	): Observable<TimeSlotResponse> {
		if (userType === permission.academy) {
			return this.http.get<TimeSlotResponse>(
				`${environment.baseUrl}academies/programs/${id}/time-slots`
			);
		} else {
			return this.http.get<TimeSlotResponse>(
				`${environment.baseUrl}playgrounds/${id}/time-slots`
			);
		}
	}

	getProgramDetails(
		id: string | null,
		userType: string
	): Observable<IProgramDetailsResponse | IPlaygroundDetailsResponse> {
		if (userType === permission.academy) {
			return this.http.get<any>(
				`${environment.baseUrl}academies/programs/${id}`
			);
		} else {
			return this.http.get<any>(
				`${environment.baseUrl}playgrounds/grounds/${id}`
			);
		}
	}

	getBookingDetailsById(id: string) {
		return this.http.get(`${environment.baseUrl}booking/${id}`);
	}

	public navigateToPaymobPayment(url: string): void {
		window.location.href = url;
	}

	public checkForApplyDiscount(
		slotData: string,
		discount: number,
		price: number
	) {
		const [slotDate, slotTime] = slotData.split(" ");
		const [hours] = slotTime.split(":").map(Number);
		// Check if the slot time falls within the discount period (09:00 - 16:00)
		if (hours >= 9 && hours <= 16) {
			// Apply the percentage discount
			price -= (price * discount) / 100;
		}
		return price;
	}
}
